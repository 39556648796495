// Base layout
.Specialists {
	display: flex;
	align-items: center;
	justify-content: center;
    flex-direction: column;
    margin-bottom: $spacing;

	@include above-mobile {
        align-items: baseline;
        flex-direction: row;
        margin-bottom: $spacing*3;
	}

	.Specialists--Item {
		width: 100%;
		max-width: 350px;
		margin-bottom: $gutter * 2;
		@include above-mobile {
			flex: 0 0 350px;
			margin-bottom: none;
		}
	}
}

.Specialists--Item {
	padding: 0 $gutter * 0.5;
	text-align: center;

	.Specialists--Item--Image {
		width: 150px;
		height: 150px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: $gutter * 2;
		border-radius: 150px;
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.6);
		object-fit: cover;
		object-position: 50% 50%;
	}

	.Specialists--Item--Content {
        display: block;
		position: relative;
		padding-left: $gutter;
		padding-right: $gutter*2;
		padding-top: $gutter * 0.5;
		padding-bottom: $gutter * 0.5;
		background: $c-white;
		border-radius: $gutter * 0.5;
		text-align: left;
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);

		&:after {
			bottom: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(255, 255, 255, 0);
			border-bottom-color: #fff;
			border-width: 10px;
			margin-left: -10px;
		}

		.Specialists--Icon-Arrow svg {
			transition: $transition-duration-default transform;
			transform: translateY(-50%) scale(1);
			transform-origin: 50% 50%;
			will-change: transform;
		}

		&:hover .Specialists--Icon-Arrow svg {
			transform: translateY(-50%) scale(1.1);
		}
	}
}

// Element styling
.Specialists--Item {
	&--name {
		color: $c-magenta;
	}

	&--title {
		@include font-xs;
		color: $c-grey;
		margin-bottom: $gutter*1.5;
		max-width: 240px;
	}

	&--location {
		@include font-xs;
		color: $c-grey;

		& + & {
			margin-left: 20px;
		}
    }
}

.Specialists--Icon-Arrow {
	position: absolute;
	top: 50%;
	right: 0.5em;
	transform: translateY(-50%) scale(0.8);
	width: $gutter*3;
	height: $gutter*3;
	border-radius: 50%;
	background: $c-grey--light;
}    
