/*  
    BubbleBlockquote Component Styles
    @animatable true
*/
.BubbleBlockquote {
    @include Bubble;
    border-left: 8px solid $c-magenta;
    padding-right: $gutter * 0.5;
    padding-left: $gutter * 1;
    width: 100%;


    @include above-mobile {
        width: auto;
        padding-right: $gutter * 1;
        padding-left: $gutter * 1.5;                
    }

    blockquote {
        @include font-xl;

        @include mobile {
            @include font-m;
        }        

        @include mobile-old {
            @include hyphenate;
        }
    }

    .BubbleContainer.left & {
        @include above-mobile {
            margin-right: -10vw;
        }
    }

    .BubbleContainer.right & {
        @include above-mobile {
            margin-left: -10vw;
        }
    }    
}