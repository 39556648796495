/* Bubble Base Styles */

@mixin bubble-default-theme {
	background: $c-white;
	color: $c-magenta;
}

@mixin bubble-magenta-theme {
	background: $c-magenta;
	color: $c-white;
}

@mixin bubble-dark-theme {
	background: $c-blue;
	color: $c-white;
}

@mixin bubble-light-theme {
	background: $c-grey--light;
	color: $c-magenta;
}


@mixin border-radius-theme($reverse: false) {
	@if $reverse {
        border-radius: 24px 0 0 24px;
        @include above-mobile {
            border-radius: 30px 0 0 30px;
        }
	} @else {
        border-radius: 0 24px 24px 0;
        @include above-mobile {
            border-radius: 0 30px 30px 0;
        }
	}
}

@mixin Bubble {

    // ====================================================
    // Base styling
    @include font-base;
    @include bubble-default-theme;
    @include border-radius-theme;
    padding-top: $gutter * 1;
    padding-right: $gutter * 1;
    padding-bottom: $gutter * 1;
    padding-left: $gutter * 1.5;
    margin-bottom: $gutter;

    @include target-metro {
        width: 100%;
    }    

    @include above-mobile {
        padding-top: $gutter * 1.3;
        padding-right: $gutter * 1.5;
        padding-bottom: $gutter * 1.3;
        padding-left: $gutter * 2;        
    }

    &.small {
		padding-top: $gutter;
		padding-right: $gutter;
		padding-bottom: $gutter * 0.8;
		padding-left: $gutter * 1.5;
    }

    &.dark {
        @include bubble-dark-theme;
    }
    
    &.light {
        @include bubble-light-theme;
    }

    // ====================================================
    // LEFT/RIGHT ALIGNMENTS
    .BubbleContainer.right & {
        @include border-radius-theme(reverse);
    }

    // For first bubble, add additonal rounder corner
    .BubbleContainer.left &:first-of-type {
        border-top-left-radius: 24px;
        @include above-mobile {
            border-top-left-radius: 30px;
        }
    }

    .BubbleContainer.right &:first-of-type {
        border-top-right-radius: 24px;
        @include above-mobile {
            border-top-right-radius: 30px;
        }
    }

    // ====================================================
    // LEFT/RIGHT ALIGNMENTS
}
