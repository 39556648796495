/* HeaderNavOverlay Component Styles */
// NAV Base layout
.HeaderNavOverlay {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.l-sitewidth {
		display: flex;
		flex-direction: column;
		padding-top: 120px;
		padding-bottom: 30px;

		@include above-tablet-portrait {
			padding-top: 30px;
			height: 100%;
		}
	}

	&--header {
	}
	&--body {
		flex: 1;
	}
	&--footer {
	}
}

// NAV Inner layout
.HeaderNavOverlay--body {
	position: relative;
	display: flex;
	flex-direction: column;

	@include above-mobile {
		flex-direction: column;
	}

	@include above-tablet-portrait {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.HeaderNavOverlay--1LevelLinks {
		@include above-tablet-portrait {
			flex-basis: calc(100% / 3);
			position: relative;
		}
	}

	.HeaderNavOverlay--body-inner {
		@include above-tablet-portrait {
			// padding-top: 8%;
			flex-basis: calc(100% / 3 * 2);
		}
	}
}

.HeaderNavOverlay--close {
	display: none;
	@include tablet-portrait {
		position: absolute;
		top: 71px;
		right: 40px;
		z-index: 31;
		width: 36px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		overflow: visible;

		p {
			font-size: 12px;
			line-height: 1;
			margin-top: 20px;
			color: $c-white;
			transition: $transition-duration-default color;
			transform: translate3d(0, 0, 0);
		}

		span {
			display: block;
			height: 2px;
			width: 36px;
			background: $c-white;
			border-radius: 2px;
		}
	}

	@include mobile {
		top: 51px;
		right: 20px;
	}

	@include mobile-old {
		margin-top: -7px;
		transform: scale(0.8);
		transform-origin: top right;
	}
}

// Chat bubble overrides specific for overlay
.HeaderNavOverlay {
	.BubbleContainer {
		padding-bottom: $gutter;
	}

	.BubbleContainer--inner {
		width: 100%;
		@include above-mobile {
			width: auto;
		}
		@include above-tablet {
			width: 80%;
		}
	}

	.BubbleContainer--time {
		color: $c-white;
	}

	.Bubble {
		@include bubble-dark-theme;
	}
}

// NAV Main links styling
.HeaderNavOverlay--1LevelLinks {
	margin-bottom: 50px;

	@include above-mobile {
		margin-bottom: 35px;
	}
	@include above-tablet {
		margin-bottom: 0;
	}

	// List styling
	.HeaderNavOverlay--1LevelLinks--list-item {
		transition: opacity 0.12s;

		// Active state
		&.is-active {
			opacity: 1;
		}

		// Hide siblings
		&.is-active ~ li {
			opacity: 0.5;
		}
	}

	.HeaderNavOverlay--1LevelLinks--list-item + li {
		padding-top: 1em;
		@include above-mobile {
			padding-top: 0.6em;
		}
		@include above-tablet {
			padding-top: 1.5em;
		}
	}

	.HeaderNavOverlay--1LevelLinks--list-item > a {
		@include font(bold);
		font-size: 40px;
		line-height: 49px;
		color: $c-magenta;
		transition: opacity 0.15s, transform 0.4s;
		cursor: pointer;
		@include above-mobile {
			@include font-xxl;
		}
	}

	// !Optional!
	// Hover effects. Hide siblings on hover
	@include above-mobile {
		> ul {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			@media (hover: hover) and (pointer: fine) {
				pointer-events: none;
			}
		}

		> ul .HeaderNavOverlay--1LevelLinks--list-item {
			@media (hover: hover) and (pointer: fine) {
				pointer-events: all;

				&.is-active {
					width: 100%;
				}
			}
		}

		> ul:hover .HeaderNavOverlay--1LevelLinks--list-item {
			opacity: 0.5;

			> div {
				opacity: 0.2;
				transition: opacity 0.12s;
			}
		}

		> ul:hover .HeaderNavOverlay--1LevelLinks--list-item:hover {
			opacity: 1;

			> div {
				opacity: 1;
			}
		}
	}
}

// NAV Header
.HeaderNavOverlay--header {
	display: none;
	justify-content: flex-end;

	@include above-tablet {
		display: flex;
	}

	a,
	.Button {
		@include font-xs;
		color: $c-grey;
		margin: 0;

		.Button--Icon {
			background: none;
			margin: 0;
		}

		&:hover .Button--Icon {
			margin-left: 5px !important;
		}
	}
}

// NAV Footer
.HeaderNavOverlay--footer {
	display: flex;
	flex-direction: column;

	@include above-tablet-portrait {
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
	}

	> ul {
		margin: 0 -25px;
		padding: 20px 0;
		text-align: center;
		border-top: 2px solid rgba($c-white, 0.16);

		@include above-tablet-portrait {
			border: none;
			margin: 0 -10px;
			padding: 0;
			width: auto;
			text-align: left;
		}
	}

	> ul li {
		display: inline-block;
		margin: 0 10px;
		@include above-tablet-portrait {
			margin: 0 10px 10px;
		}
	}

	a {
		color: $c-grey;
		@include font-xs;

		&:hover,
		&.is-active {
			color: $c-white;
		}
	}
	.HeaderNavOverlay--LangLinks {
		@include tablet-portrait {
			order: 1;
		}
	}

	.HeaderNavOverlay--2LevelLinks {
		@include tablet-portrait {
			display: flex;
			flex-direction: column;
			order: 2;
		}

		li {
			@include tablet-portrait {
				padding: 10px 0;
			}
		}

		a[href*="//"]:not([href*="tn-ict"]):after {
			content: '';
			width: 1.5em;
			height: 1.15em;
			display: inline-block;
			background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E %3Cpath fill='%23858585' fill-rule='nonzero' d='M9.46916186 8.02165705c0-.43722543.35444124-.79166667.79166666-.79166666l7.50918112-3e-8v7.50918114c-5e-8.43722538-.35444129.79166662-.79166667.79166667-.43722545-3e-8-.79166669-.35444127-.79166671-.79166672l-.00100002-4.80573337-7.60422631 7.60469774c-.28340132.2834013-.72821204.30701808-1.03851492.07085032l-.08107083-.07085032c-.30916506-.30916507-.30916506-.81042068 0-1.11958575l7.60516914-7.60516913-4.8062048-.00005722c-.39747765 0-.72653864-.29292665-.78308296-.67467997z'/%3E %3C/svg%3E");
			background-size: cover;
			color: $c-white;
		}
	}

	.HeaderNavOverlay--SocialLinks {
		@include tablet-portrait {
			order: 3;
			border-top: none;
		}

		.SvgIcon.grey > svg {
			@include tablet-portrait {
				fill: $c-white;
			}
		}

		li + li {
			@include tablet-portrait {
				margin-left: 14px;
			}
		}

		a {
			transform: scale(1.4);
			display: inline-block;
			@include tablet-portrait {
				padding: 10px;
				background: $c-blue;
				border-radius: 50%;
			}
		}
	}
}

// SUBNAV + Chatbubble states
.HeaderNavOverlay--SubnavWrapper {
	display: none;

	&.subnav-is-open {
		display: flex;
	}
}

.HeaderNavOverlay--BubblesWrapper {
	display: block;

	&.subnav-is-open {
		display: none;
	}
}

// SUBNAV styling
.HeaderNavOverlay--SubnavWrapper {
	flex-direction: column;
	align-items: flex-start;
	padding-top: 30px;

	@include above-tablet-portrait {
		position: absolute;
		top: 1.4rem;
		left: 100%;
		z-index: 1;
		width: 200%;
		padding-top: 0;
	}

	.HeaderNavOverlay--SubnavParentLink {
		@include font-base;
		color: $c-white;
		margin-bottom: 10px;

		@include above-mobile {
			margin-bottom: 20px;
		}

		@include above-tablet {
			margin-bottom: 40px;
		}
	}

	.HeaderNavOverlay--SubnavLinks {
		@include above-mobile {
			column-count: 2;
			column-gap: 20px;
			width: 100%;
		}

		li + li {
			@include above-tablet-portrait {
				margin-top: 0.2em;
			}

			@include desktop {
				margin-top: 10px;
			}
		}

		a {
			@include font-base;
			position: relative;
			display: inline-block;
			color: $c-white;
			transition: color 0.4s, padding 0.4s, background 0.3s;
			will-change: padding, color;
			background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHBhdGggZmlsbC1ydWxlPSJub256ZXJvIiBmaWxsPScjZmZmJyBkPSJNMTMuMjkyODkzMiA1LjI5Mjg5MzIyYy4zOTA1MjQzLS4zOTA1MjQyOSAxLjAyMzY4OTMtLjM5MDUyNDI5IDEuNDE0MjEzNiAwTDIxLjQxNDIxMzYgMTJsLTYuNzA3MTA2OCA2LjcwNzEwNjhjLS4zOTA1MjQzLjM5MDUyNDMtMS4wMjM2ODkzLjM5MDUyNDMtMS40MTQyMTM2IDAtLjM5MDUyNDMtLjM5MDUyNDMtLjM5MDUyNDMtMS4wMjM2ODkzIDAtMS40MTQyMTM2TDE3LjU4NSAxM0g0Yy0uNTEyODM1ODQgMC0uOTM1NTA3MTYtLjM4NjA0MDItLjk5MzI3MjI3LS44ODMzNzg5TDMgMTJjMC0uNTUyMjg0Ny40NDc3MTUyNS0xIDEtMWgxMy41ODVsLTQuMjkyMTA2OC00LjI5Mjg5MzIyYy0uMzYwNDgzOS0uMzYwNDgzOTYtLjM4ODIxMzUtLjkyNzcxNTAyLS4wODMxODg2LTEuMzIwMDA2MjJ6Ii8+Cjwvc3ZnPgo=')
				no-repeat;
			background-position: -25px 4px;
			background-size: 16px auto;

			@include above-mobile {
				color: $c-grey;
				background-size: 24px auto;
			}
		}

		a:hover {
			cursor: pointer;
			color: $c-white;
			padding-left: 1.5em;
			background-position: 0 4px;
		}
	}
}

// NAV Background
.HeaderNavOverlay--background {
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0%;
	z-index: -1;

	@include above-tablet-portrait {
		position: absolute;
	}

	&--section {
		height: 25%;
		display: block;
		background: $c-blue--dark;
		transform-origin: 0 0;
		width: 0%;
		transition-property: width, height;
		will-change: width;
	}
}
