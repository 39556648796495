/*
    BubbleH2 Component Styles
    @animatable true
*/

.BubbleH3 {
	@include Bubble;
	@include mobile {
		width: 100%;
	}

	span {
		@include font-xxs;
		color: $c-grey;
	}

	span + span:before {
		content: '•';
		margin: 0 10px;
	}

	h3 {
		font: inherit;
	}

	.BubbleContainer.left & {
		@include above-mobile {
			margin-right: -10vw;
		}
	}

	.BubbleContainer.right & {
		@include above-mobile {
			margin-left: -10vw;
		}
	}
}
