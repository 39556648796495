.HeroDialog {
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $gutter * 4;
	text-align: center;

	img {
		width: 250px;
		height: 250px;
		border-radius: 150px;
		margin-bottom: $gutter * 1;
		object-fit: cover;
		object-position: 50% 50%;

		@include above-mobile {
			width: 400px;
			height: 400px;
			border-radius: 400px;
		}
	}

	h1 {
		@include font-l;
		color: $c-magenta;
	}

	h2 {
		@include font-m;
		color: $c-grey;
		margin-bottom: $gutter * 1.5;
	}

	.Button + .Button {
		margin-left: 7px;
	}

	.HeroDialog--footer {
		@include font-s;
		color: $c-grey;
	}
}

.HeroDialog--footer--country {
	display: inline-block;

	& + & {
		margin-left: 1em;
	}
}
