/* Footer Component Styles */
.Footer {
	background: $c-blue--dark;
	color: $c-white;
	padding-top: 80px;
	margin-top: 100px;

	.BubbleH2 h2 {
		@include h1;
	}

	.BubbleContainer:last-child {
		padding-bottom: 0;
	}

	.BubbleContainer--time {
		color: $c-white;
	}
}

.Footer--bottom {
	margin-top: 100px;
	padding: 40px 0;
	color: $c-white;
	@include font-s;
	background: $c-blue--dark;

	@include above-tablet {
		padding: 80px 0;
	}

	.l-sitewidth {
		display: flex;
		flex-direction: column-reverse;

		@include above-mobile {
			flex-direction: row;
			justify-content: space-between;
			align-items: baseline;
			flex-wrap: wrap;
		}

		@include above-desktop {
			flex-wrap: nowrap;
		}

		> * {
			padding: 20px 0;
			text-align: center;
			@include above-mobile {
				text-align: left;
			}
		}
	}

	a {
		@include font-xxs;
		color: $c-grey;
	}
}

.Footer--Links {
	
	@include above-mobile {
		flex: 0 0 100%;
	}

	@include above-tablet {
		flex: auto;
	}

	li {
		display: block;
		margin: 10px 0;
		@include above-mobile {
			display: inline-block;
			margin: 0;
		}
	}

	li + li {
		@include above-mobile {
			margin-left: 20px;
		}
	}

	a[href*="//"]:not([href*="tn-ict"]):after {
		content:"";
		width: 1.5em;
		height: 1.15em;
		display: inline-block;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E %3Cpath fill='%23858585' fill-rule='nonzero' d='M9.46916186 8.02165705c0-.43722543.35444124-.79166667.79166666-.79166666l7.50918112-3e-8v7.50918114c-5e-8.43722538-.35444129.79166662-.79166667.79166667-.43722545-3e-8-.79166669-.35444127-.79166671-.79166672l-.00100002-4.80573337-7.60422631 7.60469774c-.28340132.2834013-.72821204.30701808-1.03851492.07085032l-.08107083-.07085032c-.30916506-.30916507-.30916506-.81042068 0-1.11958575l7.60516914-7.60516913-4.8062048-.00005722c-.39747765 0-.72653864-.29292665-.78308296-.67467997z'/%3E %3C/svg%3E");
		background-size: cover;
		color: $c-white;
	}
}

.Footer--LangLinks {
	border-top: 1px solid $c-blue;
	border-bottom: 1px solid $c-blue;
	@include above-mobile {
		border: none;
	}
	li {
		display: inline-block;
		margin: 0 10px;
		@include above-mobile {
			margin-left: 0;
			margin-right: 0;
		}
	}

	li + li {
		@include above-mobile {
			margin-left: 20px;
		}		
	}

	a.is-active {
		color: $c-white;
	}
}

.Footer--SocialLinks {
	@include above-mobile {
		margin-left: 30px;
	}

	li {
		display: inline-block;
	}

	li + li {
		margin-left: 1.5em;
	}

	a {
		display: inline-block;
	}

	svg {
		width: 2em;
		height: 2em;
	}
}



// Page specific styles
.page--overlay {
	.Footer {
		display: none;
	}
}
