/*
    BubbleText Component Styles
    @animatable true
*/
// Element styling
.BubbleText {
	@include Bubble;

	> * + * {
		margin-top: 1em;
	}

	a {
		position: relative;

		&:after,
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 2px;
			bottom: -4px;
			left: 0;
			background: rgba($c-magenta, 0.2);
			z-index: 2;
			transition: all $transition-duration-slow;
		}

		&:after {
			width: 0;
		}

		&:hover:after {
			width: 100%;
			background: rgba($c-magenta, 1);
		}
	}

	h2 {
		@include h2;

		@include mobile {
			@include hyphenate;
		}
	}

	ul {
		@include above-mobile {
			width: 400px;
		}
	}

	ul li {
		position: relative;
		padding-left: 2em;
		margin-bottom: 0.5em;

		&:last-child {
			margin: 0;
		}

		&:before {
			content: '';
			display: inline-block;
			position: absolute;
			top: 0.4em;
			left: 0;
			width: 0.7em;
			height: 0.7em;
			margin-right: 1.7em;
			background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='#{$c-magenta}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.4997544,3.46816116 C20.132606,2.8389053 21.1384845,3.64354925 20.6635488,4.39912866 L11.2349774,19.3991287 C10.949922,19.8526259 10.2950781,19.869735 9.98673072,19.4317416 L3.38673072,10.0567416 C2.92574993,9.40193932 3.67425241,8.57730835 4.37050706,8.97290758 L10.4751722,12.4414673 L19.4997544,3.46816116 Z' stroke=''/%3E%3C/svg%3E%0A");
			background-size: contain;
		}
	}
}
