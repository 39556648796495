.CookieNotice {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: $c-offwhite;
	z-index: 1;
	overflow: hidden;
	border-top: 1px solid #d6d6d6;
	border-bottom: 1px solid #d6d6d6;
	padding-top: $gutter * 1;
	padding-bottom: $gutter * 1;

	.CookieNotice--title {
		margin-bottom: 0.2em;
	}

	.CookieNotice--text {
		@include font(light);
		@include font-xxs;
		margin-bottom: $gutter;

		@include above-mobile {
			float: left;
			width: 75%;
			margin-right: 2em;
			margin-bottom: 0;
		}

		a {
			color: $c-magenta;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	.CookieNotice--button {
		float: right;
	}
}
.CookieNotice {
	opacity: 0;
	transition: opacity $transition-duration-very-slow $ease-out-cubic;

	&[data-state='visible'] {
		opacity: 1;
		transition: none;
	}
}
