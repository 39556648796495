.OptionInputGroup {
	margin-bottom: $gutter*2;

	.OptionInput {
		display: block;
		@include font-s;
	}

	.OptionInput + .OptionInput {
		margin-top: $gutter/2;
	}
}

// Custom checkbox styling
.OptionInput input {
	position: absolute; // take it out of document flow
	opacity: 0; // hide it
	
	&[type='radio']{
		appearance: radio;
	}

	&[type='checkbox']{
		appearance: checkbox;
	}

	& + label {
		position: relative;
		cursor: pointer;
		padding: 0;
		display: flex;
		align-items: center;
	}

	// Box.
	& + label:before {
		content: '';
		margin-right: 10px;
		display: inline-block;
		vertical-align: text-top;
		width: 30px;
		height: 30px;
		border-radius: 10px;
		background: $c-white;
	}

	// Box hover
	&:hover + label:before {
	}

	// Box focus
	&:focus + label:before {
	}

	// Box checked
	&:checked + label:before {
	}

	// Disabled state label.
	&:disabled + label {
		color: #b8b8b8;
		cursor: auto;
	}

	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #ddd;
	}
}

// CHECKBOX
.OptionInput input[type=checkbox] {

	// Checkmark. 
	&:checked + label:after {
		content: '';
		position: absolute;
		left: 10px;
		top: 14px;
		background: $c-white;
		width: 2px;
		height: 2px;
		background: $c-magenta;
		box-shadow: 2px 0 0 $c-magenta, 4px 0 0 $c-magenta, 4px -2px 0 $c-magenta, 4px -4px 0 $c-magenta, 4px -6px 0 $c-magenta, 4px -8px 0 $c-magenta;
		transform: rotate(45deg);
	}
}

// RADIO
.OptionInput input[type=radio] {

	// Box.
	& + label:before {
		width: 30px;
		height: 30px;
		border-radius: 30px;
	}


	// Circle
	&:checked + label:after {
		content: '';
		position: absolute;
		top: 11px;
		left: 11px;
		background: $c-magenta;
		width: 8px;
		height: 8px;
		border-radius: 8px;
	}

	// Radiobutton on hover only for supported devices
	@media(hover: hover) {
		&:hover + label:after {
			content: '';
			position: absolute;
			top: 11px;
			left: 11px;
			background: $c-magenta;
			width: 8px;
			height: 8px;
			border-radius: 8px;
		}		
	}
}

