$c-white: rgb(255, 255, 255);
$c-black: rgb(0, 0, 0);

$c-grey: rgb(133, 133, 133);
$c-grey--dark: rgb(42, 45, 52);
$c-grey--light: rgb(242, 242, 242);
$c-grey--medium: rgb(63, 66, 72);
$c-magenta: #E7344F;
$c-magenta--dark: #A02739;
$c-offwhite: #F2F2F2;
$c-blue: #18394C;
$c-blue--dark: #112E3F;

$plyr-color-main: $c-magenta !default;
