.SvgIcon {
	display: inline-block;
	position: relative;
	height: 1.2ex;
	font-size: 16px;

	> svg {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 100%;
		height: 1em;
		fill: $c-black;

		@include above-mobile {
			height: 1.2em;
		}

		@include above-tablet-portrait {
			height: 1.5em;
		}
	}

	&.white > svg {
		fill: $c-white;
	}

	&.red > svg {
		fill: $c-magenta;
	}

	&.grey > svg {
		color: $c-grey;
		fill: $c-grey;
	}
}

// ICON HOVER STATES

// BUTTON
.Button,
.BubbleVideo--closebutton,
.HeaderSearch--form--button {
	.SvgIcon {
		transition: $transition-duration-default transform;
		transform: scale(1);
		transform-origin: 50% 50%;
	}

	&:hover .SvgIcon {
		transform: scale(1.1);
	}
}

// AVATAR
.Avatar--link {
	.SvgIcon > svg {
		transition: $transition-duration-default transform;
		transform: scale(1) translateY(-50%);
		transform-origin: 50% 50%;
	}

	&:hover .SvgIcon > svg {
		transform: scale(1.1) translateY(-45%);
	}
}
