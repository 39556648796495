.Filter {
	padding-top: $gutter;
	padding-bottom: $gutter;
	margin-bottom: $gutter * 3;
	overflow: hidden;
	height: 100px;

	.FilterInner {
		display: flex;
		justify-content: center;
		flex-wrap: nowrap;
		width: 100%;

		@include mobile {
			overflow-x: auto;
			overflow-y: hidden;
			position: relative;
			justify-content: flex-start;
			top: -50px;
			padding-top: 50px;
			padding-bottom: 50px;
			margin-bottom: -100px;
			-webkit-overflow-scrolling: touch;

			a {
				flex: 0 0 auto;

				& + a {
					@include above-mobile {
						margin-left: 1em;
					}
				}
			}
		}
	}

	a {
		padding-top: $gutter * 0.8;
		padding-left: $gutter * 1.3;
		padding-right: $gutter * 1.3;
		padding-bottom: $gutter * 0.7;
		background: transparent;
		border-radius: 50px;
		@include font-xxs;
		color: $c-magenta;
	}

	a.is-active {
		background: $c-white;
		box-shadow: 0 20px 35px -18px rgba(0, 0, 0, 0.2);
	}
}

.Filter + .Filter--Search {
	margin-top: -$gutter * 3;
	@include above-mobile {
		margin-top: -$gutter * 2;
	}
}

.Filter--Search {
	position: relative;
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: $gutter * 3;

	form {
		position: relative;
	}

	label {
		display: none;
	}

	input {
		@include mobile {
			border-radius: 10px;
		}
	}

	input::placeholder {
		color: #9e9e9e;
	}

	.Filter--Search--Submit {
		position: absolute;
		top: 50%;
		right: 5px;
		padding: 0;
		box-shadow: none;
		transform: translateY(-50%);

		@include above-mobile {
			right: 0;
		}
	}
}

.Filter--Result {
	@include font-xs;
	color: $c-grey;
	padding: 14px 0;
	@include mobile {
		padding: 10px 0;
		font-size: 12px;
	}
}
