/* HeaderLogo Component Styles */
.HeaderLogo {
	position: relative;
	z-index: 1;
	margin-bottom: $gutter * 4;

	.l-sitewidth {
		display: flex;
		align-items: flex-start;
		flex-direction: row;
		justify-content: space-between;
	}
}

.HeaderLogo--logo {
	display: inline-block;
	background: $c-white;
	padding-top: $gutter * 1;
	padding-bottom: $gutter * 1;
	padding-left: $gutter * 1.4;
	padding-right: $gutter * 1.4;
	border-radius: 20px 20px 20px 0;
	border-bottom-left-radius: 0;

	@include above-mobile {
		border-radius: 30px 30px 30px 0;
		padding-top: $gutter * 2;
		padding-bottom: $gutter * 2;
		padding-left: $gutter * 2;
		padding-right: $gutter * 2;
	}

	img {
		width: 119px;

		@include above-mobile {
			width: auto;
		}
	}

	@include mobile-old {
		transform: scale(0.8);
		transform-origin: center left;
		margin-top: -7px;
		margin-bottom: -7px;
	}
}

.HeaderLogo--logo + .Button {
	position: absolute;
	left: 0;
	bottom: 0;
	margin: 0;
	transform: translateY(100%) translateY(10px);

	@include above-mobile {
		transform: translateY(100%) translateY(20px);
	}
}

.HeaderLogo--links {
	@include font-s;

	@include tablet {
		align-self: center;
		margin-right: 40px;
	}

	@include above-tablet {
		display: inline;
	}

	& > * {
		color: $c-grey;

		&.is-active {
			color: $c-grey--dark;
		}
	}

	> * + * {
		margin-left: 1em;
	}
}

.HeaderLogo--links--meta-link {
	@include tablet {
		position: absolute;
		top: 31px;
		right: 42px;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 60px;
		text-align: center;
		margin-right: 20px;
		font-size: 12px;
	}

	@include mobile {
		margin-right: 10px;
	}

	@include mobile-old {
		margin-right: 4px;
	}

	.is-TextShort {
		display: inline;
		@include above-tablet {
			display: none;
		}
	}
	.is-TextLong {
		display: none;
		@include above-tablet {
			display: inline;
		}
	}

	.SvgIcon {
		@include tablet {
			width: 27px;
			height: 20px;

			> svg {
				height: 2em;
				transform: translateY(-70%);
			}
		}
		@include above-tablet {
			margin-left: 0.5em;
		}
	}

	@include mobile-old {
		margin-top: -7px;
		transform: scale(0.8);
		transform-origin: top right;
	}
}

.HeaderLogo--links--search-link.HeaderLogo--links--search-link {
	@include above-tablet {
		margin-left: 2em;
		margin-right: 1em;
	}

	.SvgIcon {
		pointer-events: none;
	}

	@include tablet {
		position: absolute;
		top: 31px;
		right: 42px + 60px;
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		width: 60px;
		text-align: center;
		margin-right: 20px;
		font-size: 12px;

		.SvgIcon {
			width: 27px;
			height: 20px;

			> svg {
				height: 2em;
				transform: translateY(-70%);
			}
		}
	}

	@include mobile {
		margin-right: 10px;
	}

	@include mobile-portrait {
		margin-right: 0;
	}

	@include mobile-old {
		margin-top: -7px;
		margin-right: -4px;
		transform: scale(0.8);
		transform-origin: top right;
	}
}

.HeaderLogo--links--lang-link {
	@include tablet {
		display: none;
	}
}

// Page specific styles
.page--overlay {
	.HeaderLogo--logo {
		display: none;
	}
	.HeaderLogo--links {
		display: none;
	}

	.HeaderLogo .Button {
		@include mobile {
			top: 5px;
			bottom: auto;
			transform: none;
		}
	}
}
